<template>
  <div style="padding: 15px">
    <div>
      <a-form layout="inline">
        <a-form-item label="" style="width: 13%">
          <a-input v-model:value="searchForm.flowInstanceId" style="width: 100%" placeholder="审批流程ID" allowClear/>
        </a-form-item>
        <a-form-item label="" style="width: 26%">
          <a-range-picker v-model:value="createTime" style="width: 100%" @change="createTimeChange" :show-time="{ format: 'HH:mm' }"
            format="YYYY-MM-DD HH:mm:ss" :placeholder="['创建开始日期','创建结束日期']" allowClear/>
        </a-form-item>
        <a-form-item label="" style="width: 13%">
          <a-space>
            <a-button type="primary" @click="searchData">搜索</a-button>
            <a-button @click="resetData">重置</a-button>
            <a-button :loading="exportLoading"  @click="exportInstancePay" type="dashed">导出付款流程</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>
    <div style="color:#1073D2;margin: 15px 0">未付总金额(元)：￥{{ amtData.notSettledAmt || '' }}</div>
    <a-table :columns="columns" :row-key="(record) => record.flowInstanceId" :data-source="listData" bordered
      :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #notSettledAmt="{record}">
        <div style="color:#D9001B">
          {{ record.notSettledAmt }}
        </div>
      </template>
      <template #settledAmt="{record}">
        <div style="color:#0C8226">
          {{ record.settledAmt }}
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue'
import { getFlowInstancePayPage, getFlowPaySummary, flowInstancePayExport } from '@/api/yst/yst'

export default {
  setup () {
    const state = reactive({
      loading: false,
      exportLoading: false,
      searchForm: {
        flowInstanceId: ''
      },
      amtData: {},
      createTime: [],
      mirrorSearchForm: {},
      listData: [],
      columns: [
        {
          title: '审批流程ID',
          dataIndex: 'flowInstanceId'
        },
        {
          title: '总金额(元)',
          dataIndex: 'amt'
        },
        {
          title: '已支付金额(元)',
          dataIndex: 'settledAmt',
          slots: {
            customRender: 'settledAmt'
          }
        },
        {
          title: '未支付金额(元)',
          dataIndex: 'notSettledAmt',
          slots: {
            customRender: 'notSettledAmt'
          }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      setTimeout(() => {
        document.querySelector('.ant-page-container').style.background = '#fff'
        document.querySelector('.ant-page-container').style.height = '100%'
      }, 10)
    })
    const loadData = () => {
      state.loading = true
      getFlowInstancePayPage({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.total = res.data.total
          getFlowPaySummaryData()
        }
      }).finally(() => { state.loading = false })
    }
    const getFlowPaySummaryData = () => {
      getFlowPaySummary({
        ...state.searchForm
      }).then(res => {
        if (res.code === 10000) {
          state.amtData = res.data
        }
      })
    }
    const handleTableChange = (page) => {
      state.pagination = page
      loadData()
    }
    const searchData = () => {
      state.pagination.current = 1
      loadData()
    }
    const resetData = () => {
      state.createTime = []
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      searchData()
    }
    const createTimeChange = (e, v) => {
      state.searchForm.createTimeStart = v[0]
      state.searchForm.createTimeEnd = v[1]
    }
    const exportInstancePay = () => {
      state.exportLoading = true
      flowInstancePayExport(state.queryDTO, '付款流程-明细')
        .then(res => {
          setTimeout(() => {
            state.exportLoading = false
          }, 2000)
        }).finally(() => {
          state.exportLoading = false
        })
    }
    return {
      ...toRefs(state),
      loadData,
      searchData,
      resetData,
      exportInstancePay,
      createTimeChange,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
